import { lazy } from 'react'
export const Y2023EoyMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2023/eoy/myaccount-myplan-banner/index.js'));
export const Y2023SummerBannerV1 = lazy(() => import('../../assets/campaigns/2023/summer/banner/index.js'));
export const Y2023SummerLightboxV1 = lazy(() => import('../../assets/campaigns/2023/summer/lightbox/index.js'));
export const Y2023SummerMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2023/summer/myaccount-myplan-banner/index.js'));
export const Y2024BacktopaidFlyinV1 = lazy(() => import('../../assets/campaigns/2024/backtopaid/flyin/v1.js'));
export const Y2024BacktopaidMyaccountBadgeV1 = lazy(() => import('../../assets/campaigns/2024/backtopaid/myaccount-badge/index.js'));
export const Y2024BacktopaidMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2024/backtopaid/myaccount-myplan-banner/index.js'));
export const Y2024BacktopaidPricingBadgeV1 = lazy(() => import('../../assets/campaigns/2024/backtopaid/pricing-badge/v1.js'));
export const Y2024BlackfridayBannerV2 = lazy(() => import('../../assets/campaigns/2024/blackfriday/banner/v2.js'));
export const Y2024BlackfridayBillingPricingTableV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/billing-pricing-table/v1.js'));
export const Y2024BlackfridayBillingPricingTableV2 = lazy(() => import('../../assets/campaigns/2024/blackfriday/billing-pricing-table/v2.js'));
export const Y2024BlackfridayExitModalV2 = lazy(() => import('../../assets/campaigns/2024/blackfriday/exit-modal/v2.js'));
export const Y2024BlackfridayFlyinV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/flyin/v1.js'));
export const Y2024BlackfridayLightboxV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/lightbox/v1.js'));
export const Y2024BlackfridayMyaccountBadgeV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/myaccount-badge/v1.js'));
export const Y2024BlackfridayMyaccountBadgeV2 = lazy(() => import('../../assets/campaigns/2024/blackfriday/myaccount-badge/v2.js'));
export const Y2024BlackfridayMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/myaccount-myplan-banner/index.js'));
export const Y2024BlackfridayMyaccountMyplanBannerV2 = lazy(() => import('../../assets/campaigns/2024/blackfriday/myaccount-myplan-banner/v2.js'));
export const Y2024BlackfridayPricingBadgeV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/pricing-badge/v1.js'));
export const Y2024BlackfridayRefundRequestBannerV1 = lazy(() => import('../../assets/campaigns/2024/blackfriday/refund-request-banner/v1.js'));
export const Y2024BlackfridayRefundRequestBannerV2 = lazy(() => import('../../assets/campaigns/2024/blackfriday/refund-request-banner/v2.js'));
export const Y2024BlackfridaycheckoutBannerV3 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/banner/v3.js'));
export const Y2024BlackfridaycheckoutBannerV4 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/banner/v4.js'));
export const Y2024BlackfridaycheckoutExitModalV3 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/exit-modal/v3.js'));
export const Y2024BlackfridaycheckoutExitModalV4 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/exit-modal/v4.js'));
export const Y2024BlackfridaycheckoutMyaccountBadgeV1 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-badge/v1.js'));
export const Y2024BlackfridaycheckoutMyaccountBadgeV2 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-badge/v2.js'));
export const Y2024BlackfridaycheckoutMyaccountBadgeV3 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-badge/v3.js'));
export const Y2024BlackfridaycheckoutMyaccountBadgeV4 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-badge/v4.js'));
export const Y2024BlackfridaycheckoutMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-myplan-banner/v1.js'));
export const Y2024BlackfridaycheckoutMyaccountMyplanBannerV2 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-myplan-banner/v2.js'));
export const Y2024BlackfridaycheckoutMyaccountMyplanBannerV3 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-myplan-banner/v3.js'));
export const Y2024BlackfridaycheckoutMyaccountMyplanBannerV4 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/myaccount-myplan-banner/v4.js'));
export const Y2024BlackfridaycheckoutRefundRequestBannerV1 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/refund-request-banner/v1.js'));
export const Y2024BlackfridaycheckoutRefundRequestBannerV2 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/refund-request-banner/v2.js'));
export const Y2024BlackfridaycheckoutRefundRequestBannerV3 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/refund-request-banner/v3.js'));
export const Y2024BlackfridaycheckoutRefundRequestBannerV4 = lazy(() => import('../../assets/campaigns/2024/blackfridaycheckout/refund-request-banner/v4.js'));
export const Y2024NewuserBannerV2 = lazy(() => import('../../assets/campaigns/2024/newuser/banner/v2.js'));
export const Y2024NewuserDummyExitModalV2 = lazy(() => import('../../assets/campaigns/2024/newuser/dummy-exit-modal/v2.js'));
export const Y2024NewuserLimitDialogV2 = lazy(() => import('../../assets/campaigns/2024/newuser/limit-dialog/v2.js'));
export const Y2024NewuserMyaccountBadgeV2 = lazy(() => import('../../assets/campaigns/2024/newuser/myaccount-badge/v2.js'));
export const Y2024NewuserMyaccountMyplanBannerV2 = lazy(() => import('../../assets/campaigns/2024/newuser/myaccount-myplan-banner/v2.js'));
export const Y2024NewuserPricingBadgeV2 = lazy(() => import('../../assets/campaigns/2024/newuser/pricing-badge/v2.js'));
export const Y2024OverquotauserBannerV1 = lazy(() => import('../../assets/campaigns/2024/overquotauser/banner/v1.js'));
export const Y2024OverquotauserMyaccountBadgeV1 = lazy(() => import('../../assets/campaigns/2024/overquotauser/myaccount-badge/index.js'));
export const Y2024OverquotauserMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2024/overquotauser/myaccount-myplan-banner/index.js'));
export const Y2024OverquotauserPricingBadgeV1 = lazy(() => import('../../assets/campaigns/2024/overquotauser/pricing-badge/v1.js'));
export const Y2024OnedollarsilverBannerV1 = lazy(() => import('../../assets/campaigns/2024/onedollarsilver/banner/v1.js'));
export const Y2024OnedollarsilverExitModalV1 = lazy(() => import('../../assets/campaigns/2024/onedollarsilver/exit-modal/v1.js'));
export const Y2024OnedollarsilverFlyinV1 = lazy(() => import('../../assets/campaigns/2024/onedollarsilver/flyin/v1.js'));
export const Y2024OnedollarsilverLightboxV1 = lazy(() => import('../../assets/campaigns/2024/onedollarsilver/lightbox/v1.js'));
export const Y2024OnedollarsilverLightboxPricingV1 = lazy(() => import('../../assets/campaigns/2024/onedollarsilver/lightbox-pricing/v1.js'));
export const Y2024OnedollarsilverMyaccountMyplanBannerV1 = lazy(() => import('../../assets/campaigns/2024/onedollarsilver/myaccount-myplan-banner/index.js'));
export const Y2024RegularfiftydiscountAccountBoxV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/account-box/v2.js'));
export const Y2024RegularfiftydiscountExitModalV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/exit-modal/v2.js'));
export const Y2024RegularfiftydiscountFlyinV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/flyin/v2.js'));
export const Y2024RegularfiftydiscountMyaccountMyplanBannerV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/myaccount-myplan-banner/v2.js'));
export const Y2024RegularfiftydiscountPricingBadgeV2 = lazy(() => import('../../assets/campaigns/2024/regularfiftydiscount/pricing-badge/v2.js'));
export const Y2024TestcampaignBannerV1 = lazy(() => import('../../assets/campaigns/2024/testcampaign/banner/index.js'));
export const Y2024TestcampaignBannerV2 = lazy(() => import('../../assets/campaigns/2024/testcampaign/banner/v2.js'));